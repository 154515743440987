import { Controller } from "@hotwired/stimulus"
console.log("HelloController is geladen")

export default class extends Controller {
  static targets = ["name"]

  greet() {
    const element = this.nameTarget
    const name = element.value
    console.log(`Hello, ${name}!`)
    this.element.textContent = `Hello, ${name}!`
  }
}

