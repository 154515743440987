import { Application } from "@hotwired/stimulus"

const application = Application.start()
const controllers = import.meta.globEager('./**/*_controller.js')

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

console.log("import '@hotwired/turbo-rails'")
import '@hotwired/turbo-rails'

console.log("import 'alpine-turbo-drive-adapter'")
import 'alpine-turbo-drive-adapter'

console.log("import Alpine from 'alpinejs'\n")
import Alpine from 'alpinejs'

console.log("window.Alpine = Alpine\n")
window.Alpine = Alpine

Alpine.start()
